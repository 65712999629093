// src/components/AlbumUpload.js

import React, { useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

const AlbumUpload = () => {
  const [albumName, setAlbumName] = useState('');
  const [artist, setArtist] = useState('');
  const [genre, setGenre] = useState('');
  const [releaseDate, setReleaseDate] = useState('');
  const [albumCover, setAlbumCover] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [trackNames, setTrackNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleTrackNameChange = (index, value) => {
    const newTrackNames = [...trackNames];
    newTrackNames[index] = value;
    setTrackNames(newTrackNames);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setTracks(files);
    setTrackNames(files.map(() => ''));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const formData = new FormData();
    formData.append('albumName', albumName);
    formData.append('artist', artist);
    formData.append('genre', genre);
    formData.append('releaseDate', releaseDate);
    formData.append('albumCover', albumCover);

    tracks.forEach((track, index) => {
      formData.append('tracks', track);
    });

    // Enviar trackNames como JSON string
    formData.append('trackNames', JSON.stringify(trackNames));
    try {
      const response = await axios.post('https://hml-tempo.dreaminglab.com.br/createAlbum', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      alert('Release sent with success');
      setIsLoading(false);
      window.location.href = "/upload"
    } catch (error) {
      alert('Error while sending release');
      console.error('Error while sending album:', error);
    }
  };
  if (isLoading) {
    return (
      <h2>Uploading album. Please wait</h2>
    )
  } else {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <div>
            <Link to={'/list'}><h3>Album List</h3></Link>
          </div>
          <div>
            <Link to={'/upload'}><h3>Album Upload</h3></Link>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Release Name:</label><br />
            <input required type="text" value={albumName} onChange={(e) => setAlbumName(e.target.value)} />
          </div>
          <div>
            <label>Artist:</label><br />
            <input required type="text" value={artist} onChange={(e) => setArtist(e.target.value)} />
          </div>
          <div>
            <label>Genre:</label><br />
            <input required type="text" value={genre} onChange={(e) => setGenre(e.target.value)} />
          </div>
          <div>
            <label>Release Date:</label><br />
            <input required type="date" value={releaseDate} onChange={(e) => setReleaseDate(e.target.value)} />
          </div>
          <div>
            <label>Release Cover:</label><br />
            <input required type="file" accept="image/*" onChange={(e) => setAlbumCover(e.target.files[0])} />
          </div>
          <div>
            <label>Tracks:</label><br />
            <input required type="file" accept="audio/*" multiple onChange={handleFileChange} />
            {tracks.map((track, index) => (
              <div key={index}>
                <label>{track.name} {index + 1}:</label><br></br>
                <input
                  required
                  type="text"
                  value={trackNames[index]}
                  onChange={(e) => handleTrackNameChange(index, e.target.value)}
                  placeholder={`Input a name for track file ${track.name}`}
                />
              </div>
            ))}
          </div>
          <button type="submit">Submit Release</button>
        </form>
      </>
    );
  }
};

export default AlbumUpload;
