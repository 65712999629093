import logo from './logo.svg';
import './AlbumUpload.css';
import './AlbumList.css';
import AlbumUpload from './components/AlbumUpload';
import AlbumList from './components/AlbumList';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <h1>Music Upload</h1>
      </header>
      <main>
        <AlbumUpload />
      </main>
      <main>
        <AlbumList />
      </main>
    </div>
  );
}

export default App;
