import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

const AlbumList = () => {
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const response = await axios.get('https://hml-tempo.dreaminglab.com.br/albuns');
        setAlbums(response.data);
      } catch (error) {
        console.error('Erro ao buscar álbuns:', error);
      }
    };

    fetchAlbums();
  }, []);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <div>
          <Link to={'/list'}><h3>Album List</h3></Link>
        </div>
        <div>
          <Link to={'/upload'}><h3>Album Upload</h3></Link>
        </div>
      </div>
      <div className="container">
        {albums.map(album => (
          <div className="album" key={album._id}>
            {album.albumCover && <img src={`https://hml-tempo.dreaminglab.com.br/${album.albumCover}`} alt="Capa do Álbum" />}
            <div className="album-details">
              <h2>{album.albumName}</h2>
              <p>Artist: {album.artist}</p>
              <p>Genre: {album.genre}</p>
              <p>Release Date: {album.releaseDate}</p>
              <div className="tracks">
                {album.tracks.map((track, index) => (
                  <div className="track" key={index}>
                    <p>{track.trackName}</p>
                    <audio controls>
                      <source src={`https://hml-tempo.dreaminglab.com.br/${track.path}`} type="audio/mpeg" />
                      Seu navegador não suporta o elemento de áudio.
                    </audio>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AlbumList;
